import './App.css';
import { Description } from './components/Description';
import {NavBar} from './components/NavBar'
import { Projects } from './components/Projects';
import { Footer } from './components/Footer';
import {Skills} from './components/Skills'
import {Contact} from './components/Contact'



function App() {
  return (
    <div className="Appp">
      <NavBar /> 
      <Description />
      <Skills />
      <Projects />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
