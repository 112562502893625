import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import git from '../assets/img/git.svg'
import linkedin from '../assets/img/linkedin.svg'

export const Footer = () => {
    var currentTime = new Date()
    var year = currentTime.getFullYear()
    return (
        <footer className="footer" id="footer">
            <Container>
                <Row className="align-item-center">
                    <Col >
                        <p className="fs-6">Made with ❤️ by Abdessamed, ©{year}.</p>
                    </Col>
                    <Col className="text-center">
                        <div className="social-icon-footer ">

                            <div className="iconBox" >
                                <a href="https://github.com/Abdessamed272" target="_blank">
                                    <img src={git} alt="img"></img>
                                </a>
                            </div>
                            <div className="iconBox" >
                                <a href="https://www.linkedin.com/in/abdessamed-belhadj" target="_blank">
                                <img src={linkedin} alt="img"></img>
                                </a>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
