import React from 'react'
import git from '../assets/img/git.svg'

export const ProjectCard = ({title, description, imgUrl, gitUrl}) => {
  return (
        <div className="project-card align-items-center justify-content-center">

            <img src={imgUrl} alt="img"></img>
            <div className="proj-txtx">
                 <h4>{title}</h4>
                 <span>{description}</span>
                 <div className="iconGithubProject" >
                  <a href={gitUrl} target="_blank">
                  <img src={git} alt="img"></img>
                </a>
              </div>
            </div>

    </div>
  )
}
