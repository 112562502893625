import { useState, useRef } from "react";
import emailjs from '@emailjs/browser';
import { Container, Row, Col } from "react-bootstrap";
import 'animate.css';
import TrackVisibility from 'react-on-screen';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export const Contact = () => {
  const formInitialDetails = {
    Firstname: '',
    Lastname: '',
    email: '',
    phone: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);

  const SERVICE_ID = "service_himrsjg";
  const TEMPLATE_ID = "template_oln77le";
  const PUBLIC_KEY = "hp2-coraQ5wASGrys";


  
  const notifyA = () => toast.success('Messages sent successfully!', {
    position: "bottom-center",
    autoClose: 900,
    hideProgressBar: true,
    closeOnClick: false,
    pauseOnHover: false,
    draggable: false,
    progress: undefined,
    });



  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: formDetails[category] === value ? value : formDetails[category] + ' ' + value
    })
  }


  const form = useRef();

  const sendEmail = (e) => {
    
    e.preventDefault();
    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY)
      .then((result) => {

        if (result.text === "OK") {
          notifyA();
        }
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <section className="contact" id="connect">

      <Container>
        <Row className="align-items-center">
          <TrackVisibility>
            {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn animate__faster" : ""}>
                <h2>Get In Touch</h2>
                {/* <h6>Have an exciting project where you need some help? Send me over a message, and let's chat.</h6> */}
                <form ref={form}>
                  <Row >
                    <Col className="px-1">
                      <input type="text" placeholder="First Name" onChange={(e) => onFormUpdate('Firstname', e.target.value)} />
                      <input type="hidden" name="Firstname" value={formDetails.Firstname} />
                    </Col>
                    <Col className="px-1">
                      <input type="text" placeholder="Last Name" onChange={(e) => onFormUpdate('Lastname', e.target.value)} />
                      <input type="hidden" name="Lastname" value={formDetails.Lastname} />
                    </Col>
                  </Row>
                  <Row className="px-1">
                    <input type="email"  placeholder="Email Address" onChange={(e) => onFormUpdate('email', e.target.value)} />
                       <br />
                       <br />
                    <input type="hidden" name="user_email" value={formDetails.email} />
                  </Row>
                  <Row className="px-1" >
                    <textarea rows="6" placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
                    <input type="hidden" name="message" value={formDetails.message} />
                    <button onClick={sendEmail}><span>Send</span></button>
                    <ToastContainer />
                  </Row>
                </form>
              </div>}
          </TrackVisibility>
        </Row>

      </Container>
    </section>
  );




}
