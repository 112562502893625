import React from 'react'
import { ProjectCard } from './ProjectCard'
import laptop from "../assets/img/alll.jpg"
import projImg1 from "../assets/img/project-img12.png"
import projImg2 from "../assets/img/project-img21.png"
import projImg3 from "../assets/img/project-img31.png"
import projImg4 from "../assets/img/project-img41.png"
import { Parallax } from 'react-scroll-parallax';


export const Projects = () => {

    const projects = [
        {
            title: "QuizziCall",
            description: `QuizziCall is a simple web app built in React it lets you test your knowledge about dev tech on a wide variety topics.
             The Q&A are fetched from quizapi.io API`,
            imgUrl: projImg1,
            gitUrl: `https://github.com/Abdessamed272/super-duper-waffle`
        },
        {
            title: "Manu_Auto",
            description: "A tutorial website to help users repair their car on their own, the application is built in MVC .NET using Identity to manage Auth and authorization and Azure SQL Database",
            imgUrl: projImg2,
            gitUrl: `https://github.com/Abdessamed272/eLearningWebSite`
        },
        {
            title: "battleship Game",
            description: `Bataille Navale is a console game for two players, the application is built entirely with .NET and use UDP Socket Protocol 
            for communication` ,
            imgUrl: projImg3,
            gitUrl: `https://github.com/JohannesP75/BatailleNavale`
        }
        ,
        {
            title: "This Portfolio",
            description: `This portfolio is a single page web app built with React, using different package like react-scroll-parallax for animation effect ..etc, 
            the app is hosted on firebase and still under construction..`,
            imgUrl: projImg4,
            gitUrl: `https://github.com/Abdessamed272`
        }
    ]
    return (
        <section className="project" id="projects">
            <Parallax translateY={['0px', '50px']} opacity={[-0.5, 1.5]} easing={'easeOutQuad'}>
                <div className="image-quote">

                <div className="quote"> "Always code as if the guy who ends up maintaining your code will be a violent psychopath who knows where you live."
                <br/>- John F. Woods</div>
                <img src={laptop} />
                </div>

            </Parallax>
            <Parallax translateY={['0px', '300px']} opacity={[3, 0]} scale={[0.9, 1, 'easeInCubic']}  >

                <div >

                    <h2>Projects</h2>
                    <p>Here are a few past projects I've worked on:</p>

                    <div className="container overflow-hidden">
                        <div className="row gx-5 gy-5">
                                {/* project-container */}
                                {projects.map((project, index) => {
                                    return (
                                        <div className="col-sm-6 col-lg-4">
                                        <ProjectCard
                                            key={index}
                                            {...project}
                                            />
                                            </div>
                                    )
                                })}

                        </div>
                    </div>
                </div>
            </Parallax>
        </section>
    )
}

