import React from 'react'
import {useState, useEffect} from 'react'
import { Container, Row, Col} from 'react-bootstrap'


// import headerImg from '../assets/img/ash.jpg'
import { useParallax } from 'react-scroll-parallax';
import { Parallax } from 'react-scroll-parallax';

export const Description = () => {
    const [indexText, setIndexText] = useState(0)
    const [isDeleting, setIsDeleting] = useState(false)
    const [text, setText] = useState('')
    const [delay, setDelay] = useState(300)
    const listText = ["C# .Net ", "Angular ", "React ", "JavaScript ", "TypeScript "]


        // const { ref } = useParallax({ speed: -5 });
  const { ref } = useParallax({ translateY: ['-200px', '200px'], scale: [0, 1, 'easeOutBack'] });

    useEffect(()=>{

        let typing = setInterval(() =>
        {
            updateText()
        }, delay)  
        return() =>{ clearInterval(typing)
        }

    }, [text])

    const updateText = ()=>{
        let i = indexText % listText.length
        let fullText = listText[i]
        let showText = isDeleting ? fullText.substring(0, text.length-1) : fullText.substring(0, text.length+1)
        if(!isDeleting && showText === '') 
        {
            setText(fullText)   
        }
        if (!isDeleting && showText.length == fullText.length)
        {
            setIsDeleting(true)
            setDelay(prevDelay => prevDelay/2)
        }
        if(isDeleting && showText === '' )
        {   
            setDelay(250 - Math.random() * 100)
            setIsDeleting(false)
            setIndexText(prevIndx => ++prevIndx)
         }
 
         setText(showText)
        }
        
  return (
    
    
    <section className="description " id='home'>
         <div ref={ref} className="descImg">
        <Container>
            <Row className="align-items-center">
                <Parallax translateY={['00px', '200px']} opacity={[25,0]}  easing={'easeInOutCubic'}>

                    <div  className="title text-sm-center d-sm-inline-flex">
                            Welcome to my portfolio 
                    </div>
                        <h4 className="intro">
                            Hi, I'm Abdessamed, a web developer
                        </h4>
                        <h4 className="intro">
                            I enjoy coding with <span className="wrap"> {text}</span>
                        </h4>
                        
                    <Col sm={8}> 
                    <p  className="desc">

                    I primarly use C#, but picking up and learning a new framwork isn't a problem.
                    Currently i'm learning Angular 13 and working on a simple social network web application.
                    </p>
                    
                    </Col>
                </Parallax>
           </Row>
        </Container>
                </div>
    </section>
    )
}
