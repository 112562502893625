import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import TrackVisibility from 'react-on-screen';
// import 'animate.css';
// import {Parallax} from 'react-parallax'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import meter1 from '../assets/img/u.svg'
import meter from '../assets/img/meter.svg'
import cert from '../assets/img/ce.png'
import { Parallax } from 'react-scroll-parallax';


export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  // easing={'easeInCubic'}
  return (
    <section className='skill' id="skills">

      <Parallax translateY={['0px', '100px']} opacity={[0.2, 3]} scale={[0.9, 1, 'easeInCubic']} >

        <Container>
          <Row>
            <Col >
              <div className="skill-bx">
                <h2>
                  My Skills
                </h2>

                <Carousel id="carousel-slider" responsive={responsive} infinite={true} className="skill-slider">
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">70%</div>
                    </div>
                    <h5> C# .Net Core</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">60%</div>
                    </div>
                    <h5> React Js</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">50%</div>
                    </div>
                    <h5> Angular</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">75%</div>
                    </div>
                    <h5> TypeScript</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">100%</div>
                    </div>
                    <h5> Azure Fundamentals <br></br> (Certified)   </h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">60%</div>
                    </div>
                    <h5> API Rest</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">70%</div>
                    </div>
                    <h5> SQL</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">60%</div>
                    </div>
                    <h5> Entity Framework</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">70%</div>
                    </div>
                    <h5> Docker</h5>
                  </div>
                  <div>
                    <div className="container">
                      <img src={meter} alt="img"></img>
                      <div className="meter">60%</div>
                    </div>
                    <h5> Kubernetes</h5>
                  </div>
                </Carousel>
              </div>
            </Col>
          </Row>
        </Container>
      </Parallax>


    </section>
  )
}


// <Parallax strength={-100}  bgImage={Code}>
// <div style={{height: "200px"}}> Normal Parralax</div>
// </Parallax>
{/* <div className="image-bg">  this is the bkg  </div> */ }
